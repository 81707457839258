/**
 * This file can be replaced during build by using the `fileReplacements` array.
 * `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
 * The list of file replacements can be found in `angular.json`.
 */

export const environment = {
  apiUrl: 'https://api.int.prolinkplus.prolinksolutions.com/api',
  applicationStageCount: 8,
  clientName: 'prolink',
  cognitoAuthenticationFlowtype: 'USER_SRP_AUTH',
  cognitoRegion: 'us-east-1',
  cognitoUserPoolId: 'us-east-1_nnVYDXU8V',
  cognitoUserPoolWebClientId: '2b1s2gl338pf6fidsk2oi9fdaj',
  googleAnalyticsId: 'UA-59096500-50',
  hotjarId: '',
  loadZenDesk: true,
  production: false,
  allowLanguageChanges: true,
  recaptchaSiteKey: '6LfbqTUbAAAAANRLGLTL7l3E6_e126BPBOZTnUF0',
  setTimeout: 30,
  smartyStreetsKey: '104582367074633472',
  stateAbbreviation: 'AL',
  stateId: 1,
  timeOut: 1800,
  timestamp: '12/11/2023 11:10:27 PM',
  title: '[State Agency] - ProLink+',
  uploadFileSize: 50000000,
  zenDeskUrl: 'https://static.zdassets.com/ekr/snippet.js?key=6d19b8da-62e7-4d18-864e-1f7db58e670e',
  showReasonableAccommodation: true,
  showLanguageAssistance: true,
  showDiscriminationComplaints: true,
  showLoginPageHeroBanner: true,
  supportedLanguages: [ { name: 'English', value:'en'}, { name: 'Español', value:'es'}]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
